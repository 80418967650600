i.icon.primary {
    color: $primary;
}

i.icons i.icon.vertically.flipped:first-child {
    transform: scale(1, -1);
}

i.icons i.icon.horizontally.flipped:first-child {
    transform: scale(-1, 1);
}