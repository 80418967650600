@use 'sass:math';

.ui.modal {
    // max-height: 90vh;
    // overflow: auto;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    .ui.search .results {
        max-height: rem(150px);
        overflow: auto;
    }

    > .content {
        max-height: 80vh;
        overflow: auto;

        .table-responsive-wrap {
            overflow: auto;
            max-height: calc(80vh - rem(150px));
        }

        // table.table {
        //     overflow: hidden; // for Sticky thead and tfoot
        // }

        .ui.form table.table {
            overflow: visible; // for SearchInput

            thead tr,
            tfoot tr {
                position: static;
            }
        }
    }
}

.ui.dimmer .ui.modal .ui.loader {
    &::before {
        border-color: rgba(0, 0, 0, .1);
    }

    &::after {
        border-color: #767676 transparent transparent;
    }
}

.ui.dimmer.transparent {
    background-color: transparent;
}

.ui.modal.portaled {
    position: fixed;
    z-index: 999;
    display: block;
    visibility: visible;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    // transition: all 0.3s ease-in-out; height and width reductions when minimizing not working

    &.minimized {
        bottom: 0 !important;
        left: 1em !important;
        top: unset !important;
        right: unset !important;
        transform: none !important;
        white-space: nowrap;
        width: calc(25vw - 1rem);

        &.tab-animated {
            transition: left 0.3s ease-in-out, bottom 0.3s ease-in-out;
        }

        > .header {
            i.icon.sub-grabble {
                display: none;
            }

            > .flex {
                overflow: hidden;
                direction: rtl;

                h2 {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    direction: rtl;
                }
            }
        }

        > .content,
        > .footer {
            height: 0;
            width: 0;
            padding: 0;
            overflow: hidden;
        }
    }
}

@for $index from 0 through 16 {
    .ui.modal.portaled.minimized[data-portal-index="#{$index}"] {
        bottom: floor(math.div($index, 4)) * 5.14rem !important; // (65px / 14px)rem + 0.5rem
        left: calc((mod($index, 4)) * 25vw + (0.65em - mod($index, 4) * 0.5em)) !important;
    }
}

.ui.modal.grabbing {
    box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, .2), 0px 0px 15px 2px rgba(0, 0, 0, .2);
}

.ui.modal > .header,
.ui.modal > .content,
.ui.modal > .footer {
    background-color: transparent;
}

// .ui.modal > .header.sub-grabble,
// .ui.modal > .footer.sub-grabble {
//     &::before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         z-index: 1;
//     }

//     .ui.button {
//         z-index: 2;
//     }
// }