.flash-messages {
	position: fixed;
	left: 0;
	right: 0;
	top: rem(100px);
	z-index: 1001;
	text-align: center;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-items: center;

	.flash-message.ui.message {
		display: inline-block;
		margin-bottom: 1rem;
		padding: rem(10px) rem(20px);
		box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);

		.ui.loader {
			display: inline-block;
			position: relative;
			vertical-align: text-bottom;
			margin-right: 0.5em;
			left: 0;
			top: 0;
			transform: unset;
		}
	}
}