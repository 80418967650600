.ui.table {

    thead th,
    tbody td,
    tfoot td {
        text-align: center;
    }

    thead tr,
    tfoot tr {
        position: -webkit-sticky;
        position: sticky;
        z-index: 10;
        background-color: white;
    }

    thead tr {
        top: 0;
    }

    tfoot tr {
        bottom: 0;
    }

    thead th,
    tfoot th,
    tfoot td {
        background-color: rgba(0, 0, 0, 0.0666);
    }

    thead th {
        border-left: 1px solid rgba(34, 36, 38, .15);
        border-bottom: 1px solid rgba(34, 36, 38, .15);
    }

    tbody tr td.th {
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.0666);
        border-top: 1px solid rgba(34, 36, 38, .15);
        border-right: 1px solid rgba(34, 36, 38, .15);
    }

    tfoot {
        font-weight: bold;

        tr:first-child td {
            border-top: 1px solid $border-color;
        }
    }

    tbody.clickable,
    tr.clickable {
        transition: background-color 0.3s;

        .ui.label {
            transition: background-color 0.3s;
        }
    }

    tbody.clickable:hover,
    tr.clickable:hover {
        background-color: rgba(0, 0, 0, .1);

        .ui.label {
            background-color: white;
        }
    }

    tbody tr.sub-thead,
    tbody tr.sub-tfoot {
        background-color: rgba(0, 0, 0, 0.0666);
        font-weight: bold;
    }

    tbody tr td .flex .field {
        margin-bottom: 0;
        flex-grow: 1;
    }

    &:not(.striped) tbody:nth-child(odd) tr {
        background-color: rgba(0, 0, 0, .0333);

        &.clickable:hover {
            background-color: rgba(0, 0, 0, .1);
        }
    }

    &.striped {
        tbody tr:nth-child(even) {
            background-color: rgba(0, 0, 0, .0333);

            &.clickable:hover {
                background-color: rgba(0, 0, 0, .1);
            }
        }
    }

    tr.tr-merged {
        border-top: 0;

        td {
            border-top: 0;
        }
    }

    &.celled {
        tbody tr td.th {
            border-right: 0;
        }

        tr.tr-merged {
            td {
                border-top: 1px solid $border-color;
            }
        }
    }

    tbody tr td.td-grid {
        // padding: $cell-padding $cell-padding - 0.5em $cell-padding - 0.5em;
        gap: 0.5em;

        // > div,
        > .ui.label,
        > i,
        > button {
            // margin: 0 0.5em 0.5em;
            margin: 0;
        }
    }

    // FORM
    tr .field .ui.input,
    tr .field .ui.input input,
    // tr .ui.search .results,
    tr .field select {
        display: block;
        // max-width: rem(125px);
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    tr .field .ui.input.labeled {
        display: flex;

        input {
            width: unset;
        }
    }

    tr .search-form {
        // display: flex;
        // justify-content: center;
    }

    .ui.checkbox label {
        padding: 0;
    }

    // TODO npm module
    &.sortable thead th.sortable:after {
        transition: all 0.1s;
        content: '\f0dc';
        display: inline-block;
    }

    &.sortable thead th.sortable.ascending:after {
        content: '\f0d8';
    }

    &.sortable thead th.sortable.descending:after {
        content: '\f0d7';
    }
}

.table-loader {
    container-name: table-loader;
}

@container table-loader (min-height: 50vh) {
    .table-loader::after {
        position: fixed;
        top: 50%;
    }
}