body {
	overflow: auto;
}

.inline-flex {
	display: inline-flex;
}

.flex-column {
	flex-direction: column;
}

.border {
	border: 1px solid $border-color !important;
}

.border-left {
	border-left: 1px solid $border-color !important;
}

.border-right {
	border-right: 1px solid $border-color !important;
}

.border-bottom {
	border-bottom: 1px solid $border-color !important;
}

.border-top {
	border-top: 1px solid $border-color !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-left-0 {
	border-left: 0 !important;
}

.border-right-0 {
	border-right: 0 !important;
}

.position-relative {
	position: relative;
}

.text-primary {
	color: $primary;
}

.text-red {
	color: $red;
}

.text-overflow {
	-moz-line-clamp: 3;
	-webkit-line-clamp: 3;
	-moz-box-orient: vertical;
	-webkit-box-orient: vertical;
	display: flex;
	display: -moz-box;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	// margin: 0 auto;
	// line-height: 1.3rem;
	// max-height: 4em;
	// max-width: 20rem;
}

h2.text-overflow {
	-moz-line-clamp: 1;
	-webkit-line-clamp: 1;
}

.ui.label > a {
	opacity: 1;
}

.comment {
	text-align: justify;
	white-space: pre-line;
}

.extended {
	cursor: pointer;
}

.extended:before {
	content: '';
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.grabble,
.grabble .sub-grabble {
	cursor: move;
	/* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	position: relative;

	&:focus {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
		outline: 1px solid $primary;
	}

	> *:not(.sub-grabble):not(button):not(a):not(.clickable):not(.link) {
		cursor: auto;
	}
}

.grabble.grabbing {
	* {
		user-select: none;
	}
}

.grabble.grabbing,
.grabbing .sub-grabble {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.unselectable {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	justify-content: space-between;

	&:after {
		content: "";
		flex: auto;
	}
}

.ui.segment.basic {
	padding: 0;
	margin-top: 0;
	overflow: visible;
}

.ui.segment > .ui.divider + div {
	margin-left: 2rem;
}

.ui.segment > .ui.divider:empty {
	&::before {
		height: 100%;
	}

	&::after {
		height: 100%;
	}
}

.pagination-wrapper {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
}

.ui.label.primary {
	background-color: $primary;
	color: white;

	&.basic {
		background-color: transparent;
		border: 1px solid $primary;
		color: $primary;
	}
}