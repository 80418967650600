.ui.form .main-search .ui.input {
    width: 100%;
}

.ui.form .inline.fields .field:last-child {
    padding-right: 0;
}

.field.labeled {
    display: inline-flex;

    > .ui.label {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;
        border-radius: $border-radius 0 0 $border-radius;
        margin-right: 0;
    }

    > .ui.label + .ui.input > input,
    > .ui.label + .field > select {
        border-radius: 0 $border-radius $border-radius 0;
        margin-left: 0;
        border-left: 0;
    }

    .ui.input + .ui.label,
    select + .ui.label {
        border-radius: 0 $border-radius $border-radius 0;
        margin-left: 0;
        border-left: 0;
    }

    &.right {

        > .ui.input > input,
        > .field > select {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-right: 0;
        }
    }
}

.labeled > .ui.label:not(.dropdown) > i.icon {
    margin: 0;
}

.ui.segment .fields.inline .field:last-child {
    padding-right: 0;
}