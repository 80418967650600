.ui.pagination.menu {
    box-shadow: none;
    border: 0;
    min-height: 0;
    background-color: transparent;

    .item {
        box-shadow: none;
        min-width: 0;
        padding: 0.75rem;
        text-align: center;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        transition: all .3s;
        font-weight: bold;

        &:first-child,
        &:last-child {
            border-radius: 0;
        }

        &:before {
            content: none;
        }

        &:hover,
        &:active {
            background-color: transparent;
            border-bottom: 2px solid black;
        }

        &.active {
            padding-top: 0.75rem;
            background-color: transparent;
            color: $primary;
            border-bottom: 2px solid $primary;
        }
    }
}

.ui.pagination .item[type=ellipsisItem] {
    pointer-events: none;
}