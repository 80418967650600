/* App */
#root,
.app {
    min-height: 100vh;
    /* mobile 100vh fix */
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* Main */
main {
    flex-grow: 1;
    display: flex;
    // padding-top: rem(30px) + $header-height;
    padding-top: rem(30px);
    padding-bottom: rem(30px);

    .side {
        width: 100%;
    }

    .main-menu > .ui.menu.vertical {
        box-shadow: none;

        .item > .ui.button {
            margin: 0;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

/* Menu */
@media (max-width: 1599px) {
    .main-menu > .ui.menu.vertical {
        display: flex;
        flex-direction: row;
        width: max-content;
        margin-bottom: 1rem;

        > .item {
            display: flex;
            align-items: center;

            &.not-responsive {
                display: none;
            }

            &:before {
                flex-grow: 1;
                left: 0;
                width: 1px;
                height: 100%;
            }

            &.buttoned {
                padding: 0.5rem 1rem;

            }
        }

    }
}

@media (min-width: 1600px) {
    .main-menu {
        top: 88px;
        left: 0px;
        z-index: 99;
        position: fixed;

        .ui.menu.vertical {
            width: max-content;

            > .item.buttoned {
                padding: 0.75rem;
            }

            .item.not-responsive {
                display: block;
            }
        }
    }
}

/* Filters */

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    // justify-content: space-between;

    .main-search {
        // flex-grow: 1;
        // max-width: 375px;
        max-width: 100%;

        input {
            @media (min-width: $m) {
                min-width: 325px;
            }
        }
    }

    .ui.loader.inline {
        margin-bottom: 0;
    }

    &.ui.form {

        .field,
        .fields.inline,
        // .field:not(:last-child) .field,
        .field .field {
            margin-bottom: 0;
        }
    }

    .ui.form {
        display: inline-flex;
    }

    > .ui.segments,
    > .ui.segment {
        margin: 0;
        box-shadow: none;
    }

    > .ui.segments > .ui.segment,
    > .ui.segment {
        padding-top: 0.78571429em;
        padding-bottom: 0.78571429em;
    }
}

@media (max-width: $xl - 1px) and (min-width: $md) {
    .app .ui.container {
        width: 100%;
        margin-left: 2em !important;
        margin-right: 2em !important;
    }
}

@media (max-width: $xs - 1px) {
    .filters .main-search {
        width: 100%;

        .ui.input {
            flex-direction: column;

            .ui.dropdown,
            input,
            .ui.label {
                width: 100%;
            }

            input {
                border-left: 1px solid $border-color !important;
                border-right: 1px solid $border-color !important;
            }

            input + .ui.label {
                border-top: 0;
            }

            .ui.label {
                text-align: center;
                display: block;
                border-radius: 0;

                &:first-child {
                    border-radius: $border-radius $border-radius 0 0;
                }

                &:last-child {
                    border-radius: 0 0 $border-radius $border-radius;
                }
            }
        }
    }
}