@import '~@dometech-group/dometech-packages-style/dist';

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 448px;

$primary: #2185d0;
$red: #db2828;
$border-color: rgba(34, 36, 38, .1);
$border-radius: 0.28571429rem;

$cell-padding: .78571429em;

@include generate-unit-style('margin', 0, 1.25, 0.25, '', 'm');
@include generate-unit-style('margin-top', 0, 1.25, 0.25, '', 'mt');
@include generate-unit-style('margin-bottom', 0, 1.25, 0.25, '', 'mb');
@include generate-unit-style('margin-left', 0, 1.25, 0.25, '', 'ml');
@include generate-unit-style('margin-right', 0, 1.25, 0.25, '', 'mr');

@include generate-unit-style('padding', 0, 1.25, 0.25, '', 'p');

@import 'body';
@import 'layout';
@import 'buttons';
@import 'icons';
@import 'dropdown';
@import 'message';
@import 'pagination';
@import 'modal';
@import 'table';
@import 'form';
@import 'data';