.ui.button {
    transition: background-color 0.3s;
}

.ui.button.primary.red {
    background-color: #db2828;
}

.app .ui.button.basic:hover,
.ui.modal .ui.button.basic:hover {
    background-color: #f8f8f8 !important;
    box-shadow: 0 0 0 1px #2185d0 inset, 0 1px 4px 0 rgba(34, 36, 38, .15) inset !important;
}

.ui.label.icon > i.icon {
    margin: 0;
}

// .ui.label.close:hover {
//     background-color: rgba(0, 0, 0, .47);
//     color: white;
//     opacity: 1;
// }